import './App.css';

import React, {useState, useEffect, useRef} from 'react';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import i18n from "./components/i18n";

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Map from "./components/Map";
import {styled,  alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import SvgIcon from "@mui/material/SvgIcon";

import Button from '@mui/material/Button';
import DealerInfo from "./components/DealerInfo";
import DealerList from "./components/DealerList";
import FilterBar from "./components/FilterBar";
import Filter2 from "./components/Filter2";
import Geocode from "react-geocode";
import Geosuggest from '@ubilabs/react-geosuggest';
import { createGlobalStyle } from 'styled-components';
import GeoLocationButton from './components/GeoLocationButton';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MenuIcon from '@mui/icons-material/Menu';
import config from './config/config.json';
import dealers from './db/dealers.json';
import { AdriaLogo, ConversionIcon, ReimoLogo, RentalLogo, ShoppingCartIcon, SunLivingLogo, LippertLogo } from './components/Icons';


const indexedDealers = dealers.map((d, id) => ({ id, ...d }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));




export default function App() {
  	return (
    	<Router>
	  		<Inner />
    	</Router>
  );
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Inner() {	
	let query = useQuery();
	let filter = config.filterbar;
	
	if(query.get("spec") != null) {
		filter.forEach((value, keys) => {
			value.visible = false;
		})
		
		filter.forEach((value, keys) => {
			value.specs.forEach((f) => {
				if(f == query.get("spec"))
					value.visible = true;
			})
		})
	}
	
	
	

	const [currentDealers, setCurrentDealers] = React.useState([]);

	const [currentFilters, setCurrentFilters] = React.useState(filter);
	const [highlightedDealer, setHighlightedDealer] = React.useState(null);	
  	const [directions, setDirections] = React.useState(false);
	const [language, setLanguage] = React.useState(query.get("lng") ?  query.get("lng") : config.initLanguage);
	const [zoom, setZoom] = React.useState(query.get("zoom") ?  query.get("zoom") : 10);
  	const [advice, setAdvice] = React.useState(0);
	const [currentLocation, setCurrentLocation] = React.useState(({lat: config.initLocation.lat, lng: config.initLocation.lng}));
	const [open, setOpen] = React.useState(false);
	
	  const [anchorEl, setAnchorEl] = React.useState(null);
	  const openMenu = Boolean(anchorEl);
	  const handleClickMenu = (event) => {
	    setAnchorEl(event.currentTarget);
	  };
	  const handleCloseMenu = () => {
	    setAnchorEl(null);
	  };	

	  const handleClickFilter = () => {
	    setAnchorEl(null);
	    //setOpen(true);
	  };
	
	
	  const handleClickOpen = () => {
	    setAnchorEl(null);
	    setOpen(true);
	  };
	
	  const handleClose = () => {
	    setOpen(false);
	  };

	let activeFilter = [];	  
	const _activeFilter =  currentFilters.filter(function(f) {
	        if(f.visible === true) {
	        	activeFilter.push(f.label);

	        	return f.label;
	        }
	 });	      

		
	Geocode.setLanguage(language);
	Geocode.setApiKey("AIzaSyAJM0DURlDLcS_icMWnkjK4EN_azQxbOaE");
	
	const directionsService = new window.google.maps.DirectionsService();
	
	const getAdress = React.useCallback(function callback(location)  {			  
		console.log("getAdress");

		Geocode.fromLatLng(location.lat, location.lng).then(
		  (response) => {
		    const address = response.results[0].formatted_address;
		    setCurrentAdress(address);
		  },
		  (error) => {
		    console.error(error);
		  }
		);	
	})		
	
	const [currentAdress, setCurrentAdress] = React.useState(config.initLocation.address);	
  	
  	const _setAdvice = React.useCallback(function callback(value)  {			  
		setAdvice(value);
	})	
	
  	const _setHighlightedDealer = React.useCallback(function callback(dealer)  {			  
		setHighlightedDealer(dealer);
	})	
	
  	const _setCurrentDealers = React.useCallback(function callback(dealers)  {	
		setCurrentDealers(dealers);
	})	
	
  	const _setCurrentLocation = React.useCallback(function callback(location)  {
		setCurrentLocation(location);
	})	
	
  	const _setCurrentFilters = React.useCallback(function callback(filters)  {
		setCurrentFilters(filters);
	})		
	
	
	const checkBoxChanged = function(ev) {
		if(ev.target.checked) {
			let a = [...currentFilters];
			a.map(function(f) {
				if(f.label == ev.target.value)
					f.visible = true;
			});
			setCurrentFilters(a);
		}
		else {
			let a = [...currentFilters];
			a.map(function(f) {
				if(f.label == ev.target.value)
					f.visible = false;
			});
			setCurrentFilters(a);
		}
	}
	
	const fixIconNames = function(i) {
		if(i == "AdriaLogo")
			return  <SvgIcon><AdriaLogo/></SvgIcon>
		if(i == "ConversionIcon")
			return  <SvgIcon><ConversionIcon/></SvgIcon>
		if(i == "ReimoLogo")
			return  <SvgIcon><ReimoLogo/></SvgIcon>
		if(i == "RentalLogo")
			return  <SvgIcon><RentalLogo/></SvgIcon>
		if(i == "ShoppingCartIcon")
			return  <SvgIcon><ShoppingCartIcon/></SvgIcon>
		if(i == "SunLivingLogo")
			return  <SvgIcon><SunLivingLogo/></SvgIcon>
		if(i == "LippertLogo")
			return  <SvgIcon><LippertLogo/></SvgIcon>
	  
	}
	
	const doNavigate = React.useCallback(function callback(dealer)  {	
		if(!dealer.location)
			dealer = highlightedDealer;
		
    	const origin = { lat:  currentLocation.lat, lng: currentLocation.lng };
    	const destination = { lat: dealer.location.lat, lng:  dealer.location.lng};
		setHighlightedDealer(false);
		setOpen(false);
	    directionsService.route(
	        {
	            origin: origin,
	            destination: destination,
	            travelMode: window.google.maps.TravelMode.DRIVING,
	            
	        },
	        (result, status) => {
	            if (status === window.google.maps.DirectionsStatus.OK) {
	               setDirections(result)
	            } else {
	                console.error(`error fetching directions ${result}`);
	            }
	        }
	    );		
	})	
	
	
	const handleGeolocation = React.useCallback(function callback({ coords: { latitude: lat, longitude: lng } })  {
		let location = {};
		location.lat = lat;
		location.lng = lng;
		getAdress(location);
		setCurrentLocation(location);
	})
	
	const filteredDealer = indexedDealers.filter(dealer => {
		let _dealer = null;
		currentFilters.map(f => {
			if(f.visible === true) {
				f.specs.map(s => {
					dealer.specs.map(ds => {				
						if(ds == s)
							_dealer	= dealer;									
					});			

				})				
			}			
			if(dealer.origin == "lippert" && config.useLippert == true)
				_dealer	= dealer;											
		})
		if(_dealer != null)
			return dealer;					
			
	});
	const { t, i18n } = useTranslation("",{lng: language});
	const geosuggestEl = useRef(null);

  	const onSuggestSelect = (suggest) => {  	
  		if(suggest)
	  		setCurrentLocation(({lat: suggest.location.lat, lng: suggest.location.lng}));
	}
  	return (
    	<>
    		<DealerInfo navigate={doNavigate} currentLocation={currentLocation} highlightedDealer={highlightedDealer} setHighlightedDealer={_setHighlightedDealer} language={language}/>
	  		<Box className="App-bg">	  
		  		<Box className="App-header">
		  			<Box sx={{
						  minWidth: {
							xs: 100,  
							sm: 300,  
							md: 400,  
							lg: 500,  
							xl: 800,  
						  },
					  }}>
  						<Box sx={{ flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}>
		      				<Grid container spacing={2}>
		        				<Grid item xs={12} sm={12} md={5} lg={5} xl={5}> 		 
	                    			<StyledGeoSuggest className="search" onSuggestSelect={onSuggestSelect} placeholder={currentAdress}  autoActivateFirstSuggest />
						        </Grid>
						        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
				                    <GeoLocationButton className="geosearch" onFound={handleGeolocation} language={language}/>	  			
						        </Grid>
						 	</Grid>
						</Box>
						
						{query.get("displayfilter") == "false" ? (<><br/>
							</>) :(
							<Box sx={{flexGrow: 1, display: {
								xs: 'none',
								sm: 'none',  
								md: 'block',  
								lg: 'block',  
								xl: 'block',  
								
								} }}>
				      			<Grid container spacing={2} >
				        			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
				        			>
										{config.useLippert ? (<><br/></>) : (<Filter2 config={config} activeFilters={activeFilter} activeFilters1={currentFilters.filter(function(f) { return f.visible === true;})} currentFilters={currentFilters} setCurrentFilters={_setCurrentFilters} language={language}/>)}
				        			</Grid>
				      			</Grid>
				    		</Box>  
							)}
						<Box sx={{flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							} }}>
							
						<AppBar position="static">
					        <Toolbar>
					      		<IconButton
							        aria-controls={openMenu ? 'basic-menu' : undefined}
							        aria-haspopup="true"
							        aria-expanded={openMenu ? 'true' : undefined}
							        onClick={handleClickMenu}					          
						            size="large"
						            edge="start"
						            color="inherit"
						            aria-label="open drawer"
						            sx={{ mr: 2 }}
						          >
					          		<MenuIcon />
					          	</IconButton>
					          	
								<Menu
							        id="basic-menu"
							        anchorEl={anchorEl}
							        open={openMenu}
							        onClose={handleCloseMenu}
							        MenuListProps={{
							          'aria-labelledby': 'basic-button',
							        }}
							      >
							        <MenuItem onClick={handleClickOpen}>{t('Händlerliste')}</MenuItem>
							        <Divider />
							        {currentFilters.map((f) => (
							            <MenuItem
							              key={f.label}
							              value={f.label}
							            >
							              <Checkbox
							                value={f.label}
							                checked={f.visible}
							                onChange={e => checkBoxChanged(e)}
							              />
							              {fixIconNames(f.icon)}&nbsp;
							              {t(f.label)}
							              
							            </MenuItem>
							         ))}
							         <MenuItem onClick={handleClickFilter}><Button sx={{color: "#000", backgroundColor: "#ffcc33", float: "right"}} variant="contained" >{t('Filter übernehmen')}</Button></MenuItem>
						    	</Menu>					          
					            
								<GeoLocationButton className="geosearch" onFound={handleGeolocation} language={language}/>
					          	<Search>
						            <StyledGeoSuggest  className="search" onSuggestSelect={onSuggestSelect} placeholder={currentAdress}  autoActivateFirstSuggest />
					          	</Search>
					        </Toolbar>
					      </AppBar>							
						<br/>
							
			    		</Box>  
			    		
						<Box sx={{flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}>
			      			<Grid container spacing={2} >
			        			<Grid item xs={0} sm={0} md={3} lg={3} xl={3}
			        			>
									<DealerList setHighlightedDealer={_setHighlightedDealer} navigate={doNavigate} dealers={currentDealers} language={language}/>
			        			</Grid>
			        			<Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ flexGrow: 1, minHeight: "400px" }}>
			        				<Map zoom={zoom} directions={directions} setAdvice={_setAdvice} advice={advice} currentDealers={currentDealers} setCurrentLocation={_setCurrentLocation} currentLocation={currentLocation} filters={currentFilters} filteredDealers={filteredDealer} currentDealerList={_setCurrentDealers} setHighlightedDealer={_setHighlightedDealer} language={language}/>		
			        			</Grid>
			      			</Grid>
			    		</Box>  
			    		
						<Box sx={{flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							} }}>
			      			<Grid container spacing={2} >
			        			<Grid item xs={12}  sx={{ flexGrow: 1, minHeight: "400px" }}>
			        				<Map zoom={zoom} directions={directions} setAdvice={_setAdvice} advice={advice} currentDealers={currentDealers} setCurrentLocation={_setCurrentLocation} currentLocation={currentLocation} filters={currentFilters} filteredDealers={filteredDealer} currentDealerList={_setCurrentDealers} setHighlightedDealer={_setHighlightedDealer} language={language}/>		
			        			</Grid>
			      			</Grid>
			    		</Box>  
  
			    		  	
		   			</Box>
      			</Box>
  			</Box>    
			<Dialog
		        fullScreen
		        open={open}
		        onClose={handleClose}
		        TransitionComponent={Transition}
		      >
		        <AppBar sx={{ position: 'relative' }}>
		          <Toolbar>
		            <IconButton
		              edge="start"
		              color="inherit"
		              onClick={handleClose}
		              aria-label="close"
		            >
		              <CloseIcon />
		            </IconButton>
		            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
		              {t('Händlerliste')}
		            </Typography>
		            
		          </Toolbar>
		        </AppBar>
		        <DealerList setHighlightedDealer={_setHighlightedDealer} navigate={doNavigate} dealers={currentDealers} language={language}/>

		      </Dialog>  			
  				
    	</>
  );
}

const StyledGeoSuggest = styled(Geosuggest)`
    font-size: 1rem;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    
    .geosuggest__input {
      width: 100%;
      border: 1px solid #e4dfeb;
      box-sizing: border-box;
      padding: .5rem .5rem;
      font-size: 16px;
      -webkit-transition: border 0.2s, box-shadow 0.2s;
      transition: border 0.2s, box-shadow 0.2s;
      border-radius: 2px;
      outline: none;
      background-color: #F7F7F7;
      font-family: 'Titillium Web', sans-serif;
    }
    .geosuggest__input:focus {
      border-color: #267dc0;
    }
    .geosuggest__suggests {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 25em;
      padding: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.15);
      border-top-width: 0;
      overflow-x: hidden;
      overflow-y: auto;
      list-style: none;
      z-index: 5;
      -webkit-transition: max-height 0.2s, border 0.2s;
              transition: max-height 0.2s, border 0.2s;
    }
    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }
    
    /**
     * A geosuggest item
     */
    .geosuggest__item {
      font-size: 16px;
      padding: .5em .65em;
      cursor: pointer;
    }
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      background: #f5f5f5;
    }
    .geosuggest__item--active {
      background: #267dc0;
      color: #fff;
    }
    .geosuggest__item--active:hover,
    .geosuggest__item--active:focus {
      background: #ccc;
    }
    .geosuggest__item__matched-text {
      font-weight: bold;
    }
`;

