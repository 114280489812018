import React from 'react'
import { GoogleMap, useJsApiLoader,MarkerF, DirectionsRenderer,MarkerClustererF } from '@react-google-maps/api';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useTranslation } from 'react-i18next';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 49.9664,
  lng: 8.6633
};

function rad(x) {return x*Math.PI/180;}


function Map(props) {

	const  isLoaded  = true;
	
  	const [currentInternLocation, setCurrentInternLocation] = React.useState();
  	const [adviceOpen, setAdviceOpen] = React.useState(false);
  	const [map, setMap] = React.useState(null);
  	const [filters, setFilters] = React.useState(props.filter);
  	const [highlightedDealer, setHighlightedDealer] = React.useState(null);
  	const [foundMarkers, setFoundMarkers] = React.useState([]);
	
	const { t, i18n } = useTranslation("",{lng: props.language});

  	const onLoad = React.useCallback(function callback(map) {
 		const bounds = new window.google.maps.LatLngBounds(center);
    	map.setZoom(parseInt(props.zoom));
		
    	setMap(map);
  	}, [])



  	const onUnmount = React.useCallback(function callback(map) {
    	setMap(null)
  	}, [])
  
  	const onMouseOver = React.useCallback(function callback(dealer)  {
		setHighlightedDealer(dealer);
	})
	  
  	const onMouseOut = React.useCallback(function callback()  {
	  	setHighlightedDealer(null);
	})
	  
  	const onClick = React.useCallback(function callback(dealer)  {
	  	props.setHighlightedDealer(dealer)
	})
	
  	const refreshMarkers = React.useCallback(() =>  {
	   if(map && map.getBounds()) {
		    const _foundMarkers = props.filteredDealers.filter(dealer => {
			    if (dealer.location.lat < map.getBounds().getNorthEast().lat() &&
			    	dealer.location.lng < map.getBounds().getNorthEast().lng() &&
			    	dealer.location.lat > map.getBounds().getSouthWest().lat() &&
			    	dealer.location.lng > map.getBounds().getSouthWest().lng()
			    ) 	{
			      		return dealer;
			    	}
				});
			if(_foundMarkers.length == 0) {

				setAdviceOpen(true);			
				
			}
			setFoundMarkers(_foundMarkers);
		    props.currentDealerList(_foundMarkers);
	    }
	})
	const find_closest_marker = React.useCallback(function callback()  {
			setAdviceOpen(false);
			props.setAdvice(1);
		    var lat = props.currentLocation.lat;
		    var lng = props.currentLocation.lng;
		    var R = 6371; // radius of earth in km
		    var distances = [];
		    var closest = -1;
		    for(let i=0;i<props.filteredDealers.length; i++ ) {
		        var mlat = props.filteredDealers[i].location.lat;
		        var mlng = props.filteredDealers[i].location.lng;
		        var dLat  = rad(mlat - lat);
		        var dLong = rad(mlng - lng);
		        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
		            Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
		        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		        var d = R * c;
		        distances[i] = d;
		        if ( closest == -1 || d < distances[closest] ) {
		            closest = i;
		        }
		    }
		    props.setCurrentLocation(({lat: props.filteredDealers[closest].location.lat, lng:  props.filteredDealers[closest].location.lng}));
	})


	if(props.filters != filters) {
		refreshMarkers();
		setFilters(props.filters);
		props.setAdvice(0)
	}

		
	if(currentInternLocation != props.currentLocation) {
		setCurrentInternLocation(props.currentLocation)
		props.setAdvice(0)
	}
		

    const markers = props.filteredDealers.map(d => (
		<MarkerF
	            key={d.name}
	            position={{ lat: d.location.lat, lng: d.location.lng }}
	            icon={{
	              url: (d.lippert === true || props.filter == 6 ? "/LippertPin.png" : "/ReimoPin.png"),
	                scaledSize: {
	                    width: (d.name === highlightedDealer ? 2 : 1) * (d.isReimo ? 50 : 25),
	                    height: (d.name === highlightedDealer ? 2 : 1) * (d.isReimo ? 70 : 35),
	                },

	            }}
				onClick={e => onClick(d)}
				onMouseOver={e => onMouseOver(d.name)}
				onMouseOut={e => onMouseOut()}
	          {...d}
    	/>
    ));	

  	return isLoaded ? (
	  		<>
				<GoogleMap     
			        mapContainerStyle={containerStyle}
			        defaultCenter={{
			            lat: props.currentLocation.lat,
			            lng: props.currentLocation.lng,
			        }}
					options={{
					        streetViewControl: false,disableDefaultUI: false}}
			        center={
			            props.currentLocation
			            
			        }
			        
			        onBoundsChanged={() => map && refreshMarkers()}
			        onLoad={onLoad}
			        onUnmount={onUnmount}
			        onZoomChanged={e => {
				          if (map) {
							refreshMarkers();
				          }
			        }}
					onCenterChanged={e => {
				          if (map && map.getBounds()) {
				            refreshMarkers();
				          }
			        }}
			      >
   					<DirectionsRenderer
                		directions={props.directions}
            		/>

					<MarkerClustererF
					  gridSize={90}
					  title={t('Vergrößern')}
					  maxZoom={13}
					  minimumClusterSize={4}
					
					>
					  {(clusterer) =>
					    props.filteredDealers.map(d => (
					      <MarkerF
				            key={d.name + d.location.lat + d.location.lng + d.origin}
				            position={{ lat: d.location.lat, lng: d.location.lng }}
					        clusterer={clusterer}
				            icon={{
				              url: (d.origin === "lippert" || props.filter == 6 ? "/LippertPin.png" : "/ReimoPin.png"),
				                scaledSize: {
				                    width: (d.name === highlightedDealer ? 2 : 1) * (d.isReimo ? 50 : 25),
				                    height: (d.name === highlightedDealer ? 2 : 1) * (d.isReimo ? 70 : 35),
				                },
			
				            }}					        
					        onLoad={(marker) => {  
					          marker.addListener("click", () => {
					            map.panTo(marker.getPosition());
					          });
					        }}
					        onClick={e => onClick(d)}
							onMouseOver={e => onMouseOver(d.name)}
							onMouseOut={e => onMouseOut()}
					      />
					    ))
					  }
					</MarkerClustererF>	
							      	</GoogleMap>
		      	
		      	<Dialog onClose={e => setAdviceOpen(false)} open={adviceOpen} sx={{
			      "& .MuiDialog-container": {
			        "& .MuiPaper-root": {
			          width: "100%",
			          minWidth: "400px",  
			        },
			      },
			    }}>			    
		    		<DialogTitle>{t('Hinweis')}</DialogTitle>
		    		        <IconButton
					          aria-label="close"
					          onClick={e => setAdviceOpen(false)}
					          sx={{
					            position: 'absolute',
					            right: 8,
					            top: 8,
					            color: (theme) => theme.palette.grey[500],
					          }}
					        >
					          <CloseIcon />
					        </IconButton>
		    		 <DialogContent dividers>
					    		 	<Typography gutterBottom>
					    		 	<center>
					    		 		{t('Keine Händler gefunden.')}<br/>
					    		 		<Button startIcon={<LocationSearchingIcon/>} variant="contained" color="success" onClick={find_closest_marker}>
									 	{t('nächstgelegener Händler')}
									</Button>
									</center>
					    		 	</Typography>
					 </DialogContent>   		 	
				 </Dialog>   		 	
			</>
  	) : <></>
}

export default React.memo(Map)